.additional-filter {
  padding: 10px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

@body-background: #f0f2f5;@layout-header-background: #432158;@menu-item-padding-horizontal: 20px;@layout-header-height: 50px;@btn-primary-bg: #432158;@font-size-base: 12px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;