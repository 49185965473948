@import '~antd/dist/antd.less';

.ant-select-item.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-notification {
  z-index: 999999 !important;
}

@body-background: #f0f2f5;@layout-header-background: #432158;@menu-item-padding-horizontal: 20px;@layout-header-height: 50px;@btn-primary-bg: #432158;@font-size-base: 12px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;