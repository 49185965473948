//to use variable antd import:
// @import '~antd/dist/antd.variable.less';

.auth-layout {
  width: 100%;

  .auth-layout-header {
    position: fixed;
    z-index: 99999;
    width: 100%;
  }

  .auth-layout-content {
    padding: 0 10px;
    margin-top: 64px;
  }
}

@body-background: #f0f2f5;@layout-header-background: #432158;@menu-item-padding-horizontal: 20px;@layout-header-height: 50px;@btn-primary-bg: #432158;@font-size-base: 12px;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@table-padding-vertical: 4px;@table-padding-horizontal: 4px;