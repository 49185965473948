$drawer-width: 550px;
$max-width: 100%;
$kanban-height: 80vh;
$card-width: 250px;

.eigen-kanban-wrapper {
  display: flex;
  min-height: $kanban-height;
  min-width: 100%;
  background: #fff;
  position: relative;
  .wrapper-group-kanban {
    max-height: 100vh;
    overflow-y: scroll;
  }
  .detail-card-wrapper {
    position: absolute;
    right: 0 !important;
    height: 100%;
    background: #fff;
    width: $drawer-width;
    box-shadow: -8px -2px 15px -16px rgba(0, 0, 0, 0.85);
    -webkit-box-shadow: -8px -2px 15px -16px rgba(0, 0, 0, 0.85);
    -moz-box-shadow: -8px -2px 15px -16px rgba(0, 0, 0, 0.85);
    &.detail-card-wrapper-hidden {
      display: none !important;
    }
  }

  .kanban-wrapper {
    background: #fff;
    padding: 10px;
    min-height: inherit;
    max-height: 100vh;
    // overflow: scroll;
    &.kanban-wrapper-with-detail {
      max-width: unquote('calc( #{$max-width} - #{$drawer-width} )');
    }

    &.kanban-wrapper-without-detail {
      max-width: 100% !important;
    }

    &.header-column-hidden {
      .react-kanban-column-header {
        display: none !important;
      }

      .react-kanban-board {
        padding-top: 0 !important;
      }
    }

    .react-kanban-column-header {
      position: absolute !important;
      top: 0 !important;

      width: $card-width !important;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;

      padding-bottom: 10px;
      background: #fff;
      padding-top: 10px;
      margin-bottom: 5px;

      box-shadow: -7px 14px 26px -17px rgba(0, 0, 0, 0.32);
      -webkit-box-shadow: -7px 14px 26px -17px rgba(0, 0, 0, 0.32);
      -moz-box-shadow: -7px 14px 26px -17px rgba(0, 0, 0, 0.32);

      input:focus {
        outline: none;
      }
    }

    .react-kanban-column {
      border-radius: 2px;
      background-color: #f5f5f5;
      margin-right: 8px;

      overflow: auto !important;
      min-height: $kanban-height !important;
      max-height: $kanban-height !important;
      height: $kanban-height !important;

      input:focus {
        outline: none;
      }
    }

    .custom-card-kanban {
      max-width: $card-width;
      min-width: $card-width;
      padding-left: 8px;
      padding-right: 8px;
      margin-top: 5px;

      .custom-card-content-kanban {
        border-radius: 5px !important;
        box-shadow: -1px 2px 23px -18px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: -1px 2px 23px -18px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -1px 2px 23px -18px rgba(0, 0, 0, 0.75);

        .ant-card-body {
          .title-content {
            height: 42px;
            overflow: hidden;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .body-content {
            .action-content {
              cursor: pointer;
            }
          }
        }
      }
    }

    .react-kanban-board {
      position: relative;
      min-height: inherit !important;
      padding-top: 40px !important;
    }

    .react-kanban-card-skeleton,
    .react-kanban-card,
    .react-kanban-card-adder-form {
      box-sizing: border-box;
      max-width: 250px;
      min-width: 250px;
    }
  }
}
