.column-board {
  display: flex;
  min-width: fit-content;
  .separator-column {
    min-width: 5px;
    max-width: 5px;
    height: 100%;
    background: #fff;
    margin: 0;
  }

  .column-board-dragging-over {
    border: 2px dashed green;
    background: #f6ffed;
  }

  .column-board-wrapper {
    min-width: 275px;
    max-width: 275px;
    min-height: 500px;
    margin-right: 5px;
    display: flex;

    .column-board-item-wrapper {
      min-width: inherit;
      max-width: inherit;
      min-height: inherit;

      .column-board-item {
        min-height: inherit;
        min-width: inherit;
        max-width: inherit;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 5px;
        // padding-right: 13px;
        .card-item-wrapper {
          min-width: inherit;
          max-width: inherit;
          margin-bottom: 5px;
          .card-item {
            min-width: inherit;
            max-width: inherit;
            margin-bottom: 5px;
            .ant-card {
              border-radius: 5px !important;
              box-shadow: -1px 2px 23px -18px rgba(0, 0, 0, 0.75);
              -webkit-box-shadow: -1px 2px 23px -18px rgba(0, 0, 0, 0.75);
              -moz-box-shadow: -1px 2px 23px -18px rgba(0, 0, 0, 0.75);
              .ant-card-body {
                .title-content {
                  margin-bottom: 10px;
                  .title-ticket {
                    font-size: 12px;
                    font-weight: 600;
                  }
                }
                .body-content {
                  font-size: 12px;
                  min-height: 30px;
                  max-height: 55px;
                  margin-bottom: 10px;
                  font-weight: 400;
                  overflow: hidden;
                }

                padding: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}
