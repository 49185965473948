$base-border-color: #eee;

.header-column-board {
  z-index: 1;
  display: flex;
  background: #fff;
  border: 1px solid $base-border-color;
  min-width: fit-content;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

  position: -webkit-sticky;
  position: sticky;
  top: 0;

  .header-board-column-item {
    min-width: 280px;
    max-width: 280px;
    padding: 15px 10px;
    font-weight: 500;
    border-left: 1px solid $base-border-color;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
