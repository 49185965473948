.detail-card-wrapper {
  position: relative;
  $padding-left: 20px;
  $padding-right: 20px;
  $padding-top: 20px;
  $padding-bottom: 20px;
  $margin-bottom: 20px;

  .detail-card-header {
    z-index: 1;
    top: 0;
    width: 100%;
    position: absolute;
    padding-top: 10px;
    margin-bottom: $margin-bottom;
    background-color: #fff;

    .title-content-wrapper {
      padding-top: 10px;
      padding-left: $padding-left;
      padding-right: $padding-right;
      width: 100%;
      .title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .detail-card-body {
    padding-top: 70px;
    overflow: auto;
    max-height: 90vh;
    padding-left: $padding-left;
    padding-right: $padding-right;
    .title-content-wrapper {
      width: 100%;
      margin-bottom: 10px;
      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .detail-body-item {
        margin-bottom: 10px;
        .item-title {
          font-size: 12px;
          font-weight: normal;
          margin-bottom: 5px;
        }
        .item-content {
          font-size: 12px;
          margin-right: 12px;
          // color: #00000073;
        }
        .item-content-description {
          font-size: 12px;
          margin-right: 12px;
          color: #00000073;
        }
      }
    }
  }
  .detail-card-footer {
    z-index: 1;
    bottom: 0;
    width: 100%;
    position: absolute;
    background-color: #fff;
    .detail-card-footer-content {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: $padding-left;
      padding-right: $padding-right;
    }
  }
}
