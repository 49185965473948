.header-drawer-board {
  z-index: 99;
  padding: 15px 10px;
  border-bottom: 1px solid #eee;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

  position: -webkit-sticky;
  position: sticky;
  top: 0;

  .title {
    font-weight: 500;
  }
}
