.eigen-kanban {
  .eigen-board {
    min-height: 90vh;
    max-height: 90vh;
    overflow: scroll;
    background: #fff;
    position: relative;

    .card-area {
      .ant-collapse {
        min-width: fit-content;
      }
      .ant-collapse-borderless > .ant-collapse-item {
        border-bottom: 1px solid #eee;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
        background: #f1f2f4;
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 11px 16px;
      }
    }
  }

  .eigen-drawer-board {
    min-height: 90vh;
    max-height: 90vh;
    background: #fff;
    overflow: auto;
    position: relative;
  }
}
